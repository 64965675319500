import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon,
	} from '@radix-ui/react-icons'

import './Dreams.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar,
	} from './Lib';


//-------------

function ShowGallery({did, refresh, search}) {
	const root = window.my.gallery;
	const dream = root.dreams[did];
	const mj = json_mean(dream);

	const toggleFav = async (e) => {
		const data = await server_post(
			`/api/dream/seen/${did}/${dream.seen.id || 0}/favorite/`,
				);

		if (data) {
			root.dreams[did].seen = data;
			refresh();
		}
	}

	  return (
<Box p="2" my="4" data-did={dream.id} >
    {/* Header */}
    <Flex justify="between" align="center" px="2" py="3"
    	className="dream-header"
    >
	<Heading size="2" >
          <DateStr date={dream.dt_added} />
        </Heading>

           <Tooltip content="Anonymized author code">
	<Text size="1" color="gold" >
	  {anon_show(dream.person)}
	</Text>
	   </Tooltip>

    </Flex>

    {/* Body */}
    <Box py="4" px="2" className="dream-body" >
      <Heading size="2" align="center" my="2" >
        {mj.edited_dream?.title}
      </Heading>

      <HighlightedText text={dream.text} 
      		highlightFragment={search.text}
		highlightColor='yellow' />

    </Box>

    {/* Footer */}
    <Flex justify="between" p="2"
    	className="dream-footer"
    >
      <Flex gap="3">
        <IconButton variant="ghost" onClick={toggleFav} >
	  {dream.seen && dream.seen.favorite ?
	  	<HeartFilledIcon /> : <HeartIcon />}
	</IconButton>
      </Flex>
    </Flex>
</Box>
	);
}

function ListGallery({search}) {
	if (!window.my.id)
		return null;
	
	const root = window.my.gallery;
	
	const narrow = did => {
		const dream = root.dreams[did];

		if (search.fav && dream.seen && !dream.seen.favorite)
			return false;

		if (search.text) {
			const regexp = new RegExp(search.text, 'i');
			if (!regexp.test(dream.text))
				return false;
		}

		return true;
	}

	const dids = Object.keys(root.dreams)
			.filter(did => narrow(did))
			.map(Number)
			.sort((a,b) => a - b);
	const refresh = MkRefresh();

	return (
	<>
	{dids.map(did => <ShowGallery did={did} 
		key={did} 
		refresh={refresh}
		search={search}
		/>)}
	</>
	);
}

//----------

function Gallery() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);
	const browseFetch = useRef({start : 0, more : window.my.gallery.more});
	const searchFetch = useRef({start : 0, more : false});
	const [count, setCount] = useState(0);

	const refresh = MkRefresh();

	const has_more = () => {
		let cg = window.my.current.gallery;

		if (search.text || search.fav)
			return cg.search.more;
		return cg.browse.more;
	}

	const fetch = async (e) => {
		e && e.preventDefault();

		let data = null, error = '';

		let is_search = search.text || search.fav;
		let cg = window.my.current.gallery;
		let context = is_search ? cg.search : cg.browse;

		data = await server_post('/api/dream/fetch/', {
			search_text: search.text,
			search_fav: search.fav,
			index_from : context.start,
		});

		if (data) {
			const gallery = window.my.gallery;
			gallery.dreams = { ...gallery.dreams, ...data.dreams };
			context.more = data.more;
			context.start += Object.keys(data.dreams).length;
			refresh();
			setCount( oc => oc + 1 );
		}
	}

	const shuffle = e => {
		e.preventDefault();

		const gallery_start = randn(10000);

		window.my.gallery.dreams = {};
		window.my.current.gallery.browse.more = false;
		window.my.current.gallery.browse.start = gallery_start;
		window.my.current.gallery.browse.shuffle.push(gallery_start);

		fetch();
		refresh();
	}

	useEffect( () => {
		let get = false;
		let cg = window.my.current.gallery;

		if (cg.browse.inited == false) {
			cg.browse.inited = true;
			get = true;
		}
		if (search.text || search.fav) {
			get = true;
			cg.search = {start: 0, more: false};
		}

		if (get) {
			fetch();
		}
	}, [search.text, search.fav]);


	return (
	<>
<Content>
    <Flex align="center" justify="between" m="2" gap="4" >

    <TabHeading scrollRef={scrollRef} >
      Dream&nbsp;Gallery
    </TabHeading>

    <Flex gap="2" >
    <SearchBar search={search} setSearch={setSearch} />
    {(!search.text && !search.fav) && 
       <IconButton variant="soft" onClick={shuffle} >
           <Tooltip content="Shuffle">
         <ShuffleIcon />
	   </Tooltip>
       </IconButton>}
    </Flex>

    </Flex>
</Content>

<Scrollable setScrollRef={setScrollRef} >
<Content>
  <Card>

    <ListGallery search={search} count={count} />

    {has_more() && 
	    <Button variant="soft" 
    		onClick={fetch} >
		<DoubleArrowDownIcon /> More
	     </Button>
    }
    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


export {Gallery};
