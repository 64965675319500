import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter, Routes, Route, Link, 
	useNavigate, useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	ScrollArea, Callout, Dialog, 
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon, ColorWheelIcon,
	DashboardIcon, InfoCircledIcon, Cross2Icon, GearIcon, IdCardIcon,
	ArrowLeftIcon, TransformIcon,
	} from '@radix-ui/react-icons'

import './Home.css';
import {server_post, Content, Logo, Scrollable} from './Lib';
import {Login} from './Login';
import {Dreams} from './Dreams';
import {Gallery} from './Gallery';
import {Profile} from './Profile';
import {Horoscope} from './Horoscope';

function HomeNav() {
  const [mode,setMode] = useState('dreams');
  const [other,setOther] = useState(false);
  const navigate = useNavigate();

  const sections = [
  	{section: '', Icon: ArrowLeftIcon, tip: 'Back' },
  	{section: 'dreams', Icon: MoonIcon, tip: 'My Dreams' },
  	{section: 'gallery', Icon: TransformIcon, tip: "Others' Dreams" },
  	{section: 'horoscope', Icon: ColorWheelIcon, tip: 'Horoscope' },
  ];

  const nav = dest => {
  	let url = '';

	if (!dest)
		dest = mode;
		
	url = `/${dest}`;
	setMode(dest);
	setOther(false);
	navigate(url);
  }

  const onav = link => {
  	setOther(true);
	navigate(link);
  }

	useEffect( () => {
		window.my.handlers.showBackMenu = () => {
			setOther(true);
		};
		window.my.handlers.goBack = () => {
			nav('');
		};
		return () => {
		//	window.my.handlers.notify = null;
		};
	}, []);
	


  return (
  <Flex m="2" justify="between" align="center" >
  	<Logo />

  	<Flex gap="4">
	  {sections.filter(({section}) => (section || other))
	  		.map( ({section, Icon, tip}) => (
	    <Tooltip key={section} content={tip} >
		<IconButton size="3"
			variant={(mode === section && !other) ? 
				'solid' : 'outline'} 
			onClick={e=>nav(section)}
			>
		  <Icon />
		</IconButton>
	    </Tooltip>
	  ))}
	</Flex>

  	<Flex gap="2">

  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="soft" size="2">
	  <HamburgerMenuIcon />
        <DropdownMenu.TriggerIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<IdCardIcon />} 
      	onClick={e=>onav('/profile')}
      >
		Profile
      </DropdownMenu.Item>

      <DropdownMenu.Separator />
      <DropdownMenu.Item color="red" shortcut={<ExitIcon />} 
      	onClick={e=>navigate('/logout')}
      >
		Logout
      </DropdownMenu.Item>

      <DropdownMenu.Separator />
      <DropdownMenu.Item color="blue" shortcut={<PersonIcon />}>
        {window.my.name}
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	</Flex>
  </Flex>
  );
}


function HomeFooter() {
	const [notes, setNotes] = useState([
		// {message: 'Welcome', color:'red', modal: true, html: ''},
				]);
	const timeout = 1500;

	const close = useCallback(() => {
		setNotes(prevNotes => prevNotes.slice(1));
	}, []);

	useEffect( () => {
		window.my.handlers.notify = item => {
			setNotes(prevNotes => [...prevNotes, item]);
		};
		return () => {
		//	window.my.handlers.notify = null;
		};
	}, [close, timeout]);
	

	if (!notes.length)
		return null;

	const note = notes[0];

	const showMessage = () => (
	    <Callout.Root >
	      <Callout.Icon>
	  <IconButton variant="soft" onClick={close}>
	    <Cross2Icon />
	  </IconButton>
	      </Callout.Icon>
	      <Callout.Text>
		{note.message}
	      </Callout.Text>
	    </Callout.Root>
	);

	if (!note.modal)
		setTimeout(close, timeout);

	console.log('Notify', note);

	return (
	<Flex justify="center" wrap="nowrap">
	  <Box width={window.colw}>
	  {note.html ? 
          <Dialog.Root>
	    <Dialog.Title>Page</Dialog.Title>
            <Dialog.Trigger>
	      {showMessage()}
            </Dialog.Trigger>
            <Dialog.Content>
	      <Dialog.Description>
	      </Dialog.Description>
	        <Flex justify="between" mb="4">
		  <Heading size="4">
	        Details
		  </Heading>
		<Dialog.Close>
		  <IconButton variant="ghost">
		    <Cross2Icon />
		  </IconButton>
		</Dialog.Close>
		</Flex>
              <iframe className="notify-frame" srcDoc={note.html} /> 
            </Dialog.Content>
          </Dialog.Root>
	  :
	  showMessage()}
	  </Box>
	</Flex>
		);
}

//-------------


function Welcome() {
	const navigate = useNavigate();

	useEffect( () => {
		navigate('/dreams');
	});

	return null;

	// For now, redir to dreams

	return (
	<Content>
	<Text>
		Welcome {window.my.name}!
	</Text>
	</Content>
	);
}

function Logout({refresh}) {
	const navigate = useNavigate();

	useEffect( () => {
		window.my = {...window.my_default};
		refresh();
		navigate('/');
	});

	return null;
}

function ScrollTop({scrollRef}) {
	const navigate = useNavigate();
	const {section} = useParams();

	useEffect( ()=> {
		scrollRef && scrollRef.current?.scrollTo({ top: 0 });
		navigate(`/${section}`, {replace: true});
	}, []);

	return null;
}

//-------------

function Home({refresh}) {
  const [scrollRef, setScrollRef] = useState(null);

  return (
<Flex direction="column" height="100vh" gap="0">
	<HomeNav />
	<Routes>
		<Route path="/" element={<Welcome />} />
		<Route path="/dreams" 
			element={<Dreams />} />
		<Route path="/gallery" 
			element={<Gallery />} />
		<Route path="/horoscope" 
			element={<Horoscope />} />
		<Route path="/profile" 
			element={<Profile refresh={refresh} />} />
		<Route path="/logout" 
			element={<Logout refresh={refresh} />} />

		<Route path="/top/:section" 
			element={<ScrollTop scrollRef={scrollRef} /> } />
	</Routes>
	<HomeFooter />
</Flex>
  );
}

export {Home};
