import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon,
	} from '@radix-ui/react-icons'

import './Dreams.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show, randn,
	ShowJson, cap, AiFetchPoll, SpeechToText, 
	HighlightedText, json_mean, SearchBar,
	} from './Lib';


//-------------

function AddDream({refresh, search}) {
	const [text,setText] = useState('');

	if (search.text || search.fav)
		return null;

	const add = async (e) => {
		e.preventDefault();

		let data = null, error = '';

		data = await server_post('/api/dream/add/', {text});

		if (data) {
			const did = Object.keys(data)[0];
			const my = window.my;
			my.dreams[did] = data[did];
			my.current.did = did;
			refresh();
			setText('');
		}
	}

	const ht = text ? '8rem' : '2rem';

	return (
<Box p="2">
<form>
  <TextArea 
	color="blue" variant="soft" resize="vertical" size="2"
	style={{ height: ht }}
  	placeholder="Your dream.." 
	value={text}
	onChange={e=>setText(e.target.value)}
  />
  <Flex justify="between" align="center" >
  <Button my="2" variant="outline" onClick={add}>
  	Add
  </Button>
  <SpeechToText onTranscript={buf => setText(buf)} />
  </Flex>
</form>
</Box>
	);
}

//----------

function DreamMenu({rmDream}){
	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmDream} >
      	Delete
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}

function ShowMeaning({mj, dream}) {	// mj = meaning json
	return (
<Box className="dream-meaning" >
  {mj === null ? 
        <Text>
  	  {dream.meaning}
	</Text>
	:
  	<ShowJson root={mj.interpretation} />
  }
</Box>
	);
}


function GetMeaning({dream, refresh}) {
	const [attempt, setAttempt] = useState(0);
	const [error, setError] = useState('');
	const [count, setCount] = useState(0);
	const intervalIdRef = useRef(null);
	const did = dream.id;

	const setError2 = arg => {
		console.log('getMeaning.error', arg);
		clearInterval(intervalIdRef.current);
		setError(arg);
	}

	const reset = e => {
		intervalIdRef.current = 0;
		setAttempt(0);
		setError('');
		setCount(0);
	}

	const fetch = useCallback(async () => {
		const url = attempt === 0 ? 
			`/api/dream/${did}/decode/` : 
			`/api/dream/${did}/decode/${attempt}/poll/`;
		if (!intervalIdRef.current)
			intervalIdRef.current = setInterval( () =>
				setCount( oldCount => oldCount + 1 ),
				1000);
		const data = await server_post(url, {did}, setError2);
		console.log('getMeaning.error', data);
		if (data && data.dream) {
			if (data.dream.meaning) {
				window.my.dreams[did] = {...data.dream};
				setAttempt(0);
				clearInterval(intervalIdRef.current);
				refresh();
			} else {
				setAttempt(prevAttempt => prevAttempt + 1);
			}
		}
			
	}, [attempt, did, refresh]);

	useEffect(() => {
		let timer;
		if (attempt > 0 && attempt < 30) {
			timer = setTimeout(fetch, 1000);
		}
		return () => {
			clearTimeout(timer);
			clearInterval(intervalIdRef.current);
		}
	}, [attempt, fetch]);


	return (
    <Box>
      {!intervalIdRef.current ? (
        <Button variant="soft" onClick={fetch}>
          Analyze with AI
        </Button>
      ) : (
        <Box>
          Fetching...{count}

<Callout.Root my="2" >
  <Callout.Icon>
    <InfoCircledIcon />
  </Callout.Icon>
  <Callout.Text>
    AI can typically take 15 seconds
  </Callout.Text>
</Callout.Root>
	  
        </Box>
      )}
      {error && 
      <Box my="2" p="2" style={{ background: 'var(--yellow-2)' }}>
        <Flex justify="between" align="center">
	      <Heading size="3">
		Error
	      </Heading>
	      <Button variant="soft" onClick={reset} >
			<ResetIcon /> Reset
		     </Button>
         </Flex>
	 <hr/>

        <Box className="my-pre" my="4" >
		{error}
	</Box>
      </Box>
      }
    </Box>
	);
}

function ShowDream({did, refresh, search}) {
	const root = window.my;
	const dream = root.dreams[did];
	const mj = json_mean(dream);

	const rmDream = async (e) => {
		const data = await server_post(
				'/api/dream/delete/',
				{did});

		if (data) {
			delete root.dreams[did];
			refresh();
			notify({message: 'Deleted'});
		}
	}

	const toggleFav = async (e) => {
		const data = await server_post(
			`/api/dream/${did}/toggle_favorite/`,
				);

		if (data) {
			root.dreams[did].is_favorite = data.is_favorite;
			refresh();
		}
	}

	const fetch2 = async (attempt, setError2, finish) => {
		const url = attempt === 0 ? 
			`/api/dream/${did}/decode/` : 
			`/api/dream/${did}/decode/${attempt}/poll/`;
		const data = await server_post(url, {did}, setError2);
		let dtext;
		try{
			dtext = data.dream.meaning;
			if (dtext === null)
				dtext = '';
		}
		catch {
			dtext = null;
		}
		console.log('AnalyzeDream', {data, dtext});
		if (dtext !== null) {
			let done;

			if (dtext) {
				window.my.dreams[did].meaning = dtext;
				refresh();
				done = true;
			} else {
				done = false;
			}
			finish(done);
		}
		else {
			console.log('AnalyzeDream.fetch2 empty', 
				{attempt, dtext});
		}
	}

	  return (
<Box p="2" my="4" data-did={dream.id} >
    {/* Header */}
    <Flex justify="between" align="center" px="1" py="3"
    	className="dream-header"
    >
      <Flex gap="2" align="center" >
      </Flex>

	<Heading size="2" >
          <DateStr date={dream.dt_added} />
        </Heading>

      <Flex gap="2" align="center" >
        <Text size="1" color="gray" >
        </Text>
      </Flex>
    </Flex>

    {/* Body */}
    <Box py="4" px="2" className="dream-body" >
      <HighlightedText text={dream.text} 
      		highlightFragment={search.text}
		highlightColor='yellow' />

      <Heading size="2" my="2" align="center" >
        Meaning
      </Heading>

      {dream.meaning ? 
      	<ShowMeaning dream={dream} mj={mj} /> :
	<AiFetchPoll fetch2={fetch2} />
       }

    </Box>

    {/* Footer */}
    <Flex justify="between" p="2"
    	className="dream-footer"
    >
      <Flex gap="3">
        <IconButton variant="ghost" onClick={toggleFav} >
	  {dream.is_favorite?  <HeartFilledIcon /> : <HeartIcon />}
	</IconButton>
        <IconButton variant="ghost" onClick={rmDream} >
	  <Cross1Icon />
	</IconButton>
      </Flex>
      <DreamMenu rmDream={rmDream} />
    </Flex>
</Box>
	);
}

function ListDreams({search}) {
	if (!window.my.id)
		return null;
	
	const root = window.my;
	
	const narrow = did => {
		const dream = root.dreams[did];

		if (search.fav && dream.seen && !dream.seen.favorite)
			return false;

		if (search.text) {
			const regexp = new RegExp(search.text, 'i');
			if (!regexp.test(dream.text))
				return false;
		}

		return true;
	}

	const dids = Object.keys(root.dreams)
			.filter(did => narrow(did))
			.map(Number)
			.sort((a,b) => b - a);
	const refresh = MkRefresh();

	return (
	<>
	{dids.map(did => <ShowDream did={did}
		key={did} 
		refresh={refresh}
		search={search}
		/>)}
	</>
	);
}

//----------

function Welcome() {
	if (Object.keys(window.my.dreams).length)
		return null;

	return (
<Card style={{ background: '#eeffee' }}>
  <Heading size="3" align="center">
    Welcome
  </Heading>

  <Text>
    <ol>
      <li> Add your dreams and have AI interpret them. </li>
      <li> Browse dreams other shared </li>
      <li> Optionally get your horoscopes </li>
    </ol>

    <Text as="p" align="center">
      Use the buttons at the top
    </Text>
  </Text>
</Card>
	);
}

//----------

function Dreams() {
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);

	const refresh = MkRefresh();

	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" >

      <TabHeading scrollRef={scrollRef}>
        My Dreams
      </TabHeading>

      <SearchBar search={search} setSearch={setSearch} />

    </Flex>
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
  <Card>

    <AddDream refresh={refresh} search={search}  />
    <Welcome />
    <ListDreams mine={true} search={search} />

    
  </Card>
</Content>
</Scrollable>
	</>
	);
}


export {Dreams};
