import React, { useState, useEffect, useCallback, useRef, } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, DropdownMenu, Tooltip,
	TabNav, TextArea, Callout,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import { EnterIcon, ClipboardIcon, ImageIcon, 
	EyeNoneIcon, EyeOpenIcon, DropdownMenuIcon, HamburgerMenuIcon,
	MoonIcon, LayersIcon, SunIcon, ExitIcon, PersonIcon,
	PlusIcon, DashboardIcon, Cross1Icon, DotsVerticalIcon,
	ComponentInstanceIcon, ReaderIcon, CommitIcon,
	HeartIcon, HeartFilledIcon, DotFilledIcon,
	MagnifyingGlassIcon, DoubleArrowDownIcon,
	InfoCircledIcon, ResetIcon, ShuffleIcon,
	} from '@radix-ui/react-icons';

import './Horoscope.css';
import {server_post, notify, Content, Logo, MkRefresh, Scrollable,
	DateStr, TabHeading, first_name, anon_show, randn, tojson,
	cap, ShowJson, AiFetchPoll,
	} from './Lib';

//-------------
// move this to Lib later

//-------------

function MyTabs({tab, setTab}) {

	return (
    <TabNav.Root>

      <TabNav.Link href="#" active={tab=='daily'} 
      	onClick={e=>setTab('daily')}
      >
	  <Text>
	    Daily
	  </Text>
      </TabNav.Link>

      <TabNav.Link href="#" active={tab=='weekly'}
      	onClick={e=>setTab('weekly')}
      >
	  <Text>
	    Weekly
	  </Text>
      </TabNav.Link>


      <TabNav.Link href="#" active={tab=='chart'}
      	onClick={e=>setTab('chart')}
      >
	  <Text>
	    Chart
	  </Text>
      </TabNav.Link>


    </TabNav.Root>
    	);
}

function HoroscopeMenu({rmHoroscope}){

	return (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger>
      <Button variant="ghost" size="2">
	  <DotsVerticalIcon />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content size="2">
      <DropdownMenu.Item shortcut={<Cross1Icon />} onClick={rmHoroscope} >
      	Delete
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
	);
}

function Display({jtext}) {	
	const hscope = tojson(jtext);

	return (
<Box className="horoscope" >
  {(hscope === null || !hscope.horoscope) ? 
        <Text>
  	  {jtext}
	</Text>
	:
  	<ShowJson root={hscope.horoscope} 
		ignore={'sign,birth,currentplace,date'}
	/>
  }
</Box>
	);
}


//----------

function Welcome() {
	if (window.my.astro.sign)
		return null;

	return (
<Card style={{ background: '#eeffee' }}>
  <Heading size="3" align="center">
    Welcome
  </Heading>

  <Text>
    <ol>
      <li> <Link to="/profile">Update Profile</Link> with birthday 
      to get your horoscope</li>
      <li> Optionally, add birth place and time for detailed horoscope</li>
    </ol>

  </Text>
</Card>
	);
}

//---------------------------------------------------------------

function DateRange({hscope, kind}) {
	if (kind == 'tomorrow')
		return <DateStr date={hscope.dt_from} />;
	
	return (
	<Box>
		<DateStr date={hscope.dt_from} /> 
		{' - '}
		<br/>
		<DateStr date={hscope.dt_till} /> 
	</Box>
	);
}


function ShowHoroscope({kind}) {
	const refresh = MkRefresh();
	const hRef = useRef(window.my.horoscope[kind]);

	const dur = {tomorrow: 'd', nextWeek: 'w'};

	const fetch2 = async (attempt, setError2, finish) => {
		const htype=dur[kind], dfrom=hRef.current.dt_from;

		const url = attempt === 0 ? 
	`/api/horoscope/${htype}/${dfrom}/${kind}/fetch/` : 
	`/api/horoscope/${htype}/${dfrom}/${kind}/fetch/${attempt}/poll/`;
		const data = await server_post(url, {}, setError2);
		let htext;
		try{
			htext = data.horoscope.text;
			if (htext === null)
				htext = '';
		}
		catch {
			htext = null;
		}
		console.log('horoscope.error', {data, htext});
		if (htext !== null) {
			let done;

			if (data.horoscope.text) {
				window.my.horoscope[kind] = {
					...window.my.horoscope[kind],
					...data.horoscope
				}
				hRef.current = window.my.horoscope[kind];
				refresh();
				done = true;
			} else {
				done = false;
			}
			// console.log('showHoroscope.fetch2', 
				// {done, attempt, htext});
			finish(done);
		}
		else {
			console.log('showHoroscope.fetch2 empty', 
				{attempt, htext});
		}
	}

	const rmHoroscope = async (e) => {
		const data = await server_post(
				`/api/horoscope/${hRef.current.id}/delete/`);

		if (data && !data.error) {
			hRef.current.id = 0;
			refresh();
			notify({message: 'Deleted'});
		}
	}
	const hdr = {
		tomorrow: 'Tomorrow',
		nextWeek: 'Next Week',
	};

	console.log('ShowHoroscope', kind, hRef.current);


	  return (
<Box my="4" >
    {/* Header */}
    <Flex justify="between" align="center" p="1"
    >
	<Heading size="3" >
	  {hdr[kind]}
	</Heading>


      <Flex gap="2" align="center" >
        <Text size="1" color="gray" >
	  <DateRange hscope={hRef.current} kind={kind} />
        </Text>
      <HoroscopeMenu rmHoroscope={rmHoroscope} />
      </Flex>
    </Flex>

    {/* Body */}
    <Box py="4" px="2" className="horoscope-body" >
      {hRef.current.id ? 
      	<Display jtext={hRef.current.text} /> : 
	<AiFetchPoll fetch2={fetch2} />
      }
    </Box>

</Box>
	);
}


function Chart() {
	const astro = window.my.astro;

	return (
<Box>
<div dangerouslySetInnerHTML={{ __html: astro.svg }} />
</Box>
	);
}


//----------

function Horoscope() {
	const [tab, setTab] = useState('daily');
	const [search, setSearch] = useState({text: '', fav: false});
	const [scrollRef, setScrollRef] = useState(null);

	const refresh = MkRefresh();
	const astro = window.my.astro;

	return (
	<>
<Content >
    <Flex align="center" justify="between" m="2" >

      <TabHeading scrollRef={scrollRef}>
        Horoscope
      </TabHeading>

      <Text>
      	{astro.emoji} {astro.sign}
      </Text>

    </Flex>
    <MyTabs tab={tab} setTab={setTab} />
</Content>
<Scrollable setScrollRef={setScrollRef} 
	>
<Content>
 {window.my.astro.sign ?
  <Card>
    {tab == 'daily' && <ShowHoroscope kind={'tomorrow'} />}
    {tab == 'weekly' && <ShowHoroscope kind={'nextWeek'} />}
    {tab == 'chart' && <Chart />}
  </Card>
  :
    <Welcome />
  }
</Content>
</Scrollable>
	</>
	);
}


export {Horoscope};
