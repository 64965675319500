import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, 
	useNavigate, useParams, } from 'react-router-dom';

import '@radix-ui/themes/styles.css';
import { Theme, Box, Flex, Container, Section, Text, Heading, 
	Button, Card, TextField, IconButton, Callout,
	} from '@radix-ui/themes';
import { Link as LinkRadix } from '@radix-ui/themes';

import './Expt.css';
import { EnterIcon, ClipboardIcon, ImageIcon, SunIcon,
	EyeNoneIcon, EyeOpenIcon, InfoCircledIcon
	} from '@radix-ui/react-icons'


import {server_post, Content,
	SpeechToText,
	} from './Lib';

function Sample() {
  const handleTranscript = (transcript) => {
    // Here you can send the transcript to your server or OpenAI for analysis
    console.log('New transcript:', transcript);
  };

  return (
    <div className="App">
      <h1>Dream Narrator</h1>
      <SpeechToText onTranscript={handleTranscript} />
      {/* Your existing app components */}
    </div>
  );
}


function Expt({refresh}) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const emailRef = useRef(null);

	const login = async (e) => {
		e.preventDefault();

		let data = null, error = '';

		data = await server_post('/api/login/',
					{username:email, password},
					setError);
		if (data && !data.error) {
			window.my = {
				...window.my,
				...data.my,
			};
			navigate('/');
			refresh();
		}
	}

	useEffect( () => {
		if (emailRef.current)
			emailRef.current.focus();
	}, []);

	return (
<Content>
  <Card variant="classic" size="4">
    <form action="/">
      <Box height="40px" mb="4">
        <Heading as="h3" size="6" mt="-1">
	  Expt
	</Heading>
      </Box>

      <Box mb="5">
        <Text as="label" size="2" weight="medium" mb="2" htmlFor="email">
	  Line
	</Text>
	<TextField.Root
	  id="email"
	  type="email"
	  variant="classic"
	  placeholder="text"
	  ref={emailRef}
	  value={email}
	  onChange={e=>setEmail(e.target.value)}
	 />
      </Box>

      <SpeechToText onTranscript={setEmail} />

      <Flex mt="6" justify="end" gap="3">
        <Button variant="solid" type="submit" onClick={login} >
	  Click
	</Button>
      </Flex>

      {error && 
      <Text color="red">
      	{error}
      </Text>}

    </form>
  </Card>
</Content>
	);
}


export {Expt};
